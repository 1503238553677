@import '../../variables.scss';

.container {
  padding: 0 20px;
}

@media screen and (max-height: 650px) {
  .login-box {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .login-box {
    padding: 40px 30px !important;
  }
}

.login-box {
  position: relative;
  margin: auto;
  margin-top: 15vh;
  padding: 40px;
  border-radius: 4px;
  max-width: 425px;
  height: 460px;
  box-shadow: 0 5px 13px 0 rgba(0, 0, 0, 0.36);
  border-radius: 0;
  > h1 {
    font-size: 24px;
    line-height: 33px;
    margin: 0;
    color: $dark-text-color;
  }
  > h2.login-title {
    color: $dark-text-color;
    padding: 20px 0;
    margin: 0;
  }
  .username,
  .password {
    padding-top: 20px;
    width: 100%;
  }

  .forgot-password {
    cursor: pointer;
    user-select: none;
    margin-top: 20px;
    color: $light-text-color;
    text-align: right;
  }

  .login-btn {
    @media screen and (max-width: 560px) {
      right: 20px;
    }
    transition: all ease .2s;
    position: absolute;
    right: 40px;
    bottom: 30px;
  }
}
