$color-primary: #37f;
$color-default: #f7f7f7;

$border-shadow-color: rgba(0, 0, 0, 0.36);

$dark-text-color: #1a2b3c;
$light-text-color: #c2c5cc;

$input-placeholder-color: $light-text-color;
$input-border-color: #ededf0;
$input-border-focus-color: $color-primary;

$button-invert-text-color: $color-default;
$button-background-primary: $color-primary;
$button-background-default: $color-default;
