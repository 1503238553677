@import "../../variables.scss";

.input {
  display: block;
  border: none;
  border-bottom: 1px solid $input-border-color;
  border-radius: 0;
  width: 100%;
  padding: 0;
  transition: border-bottom-color ease .2s;
  font-size: 18px;
  height: 34px;
  &:focus {
    outline: none;
    border-bottom-color: $input-border-focus-color;
  }
}
