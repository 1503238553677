@import "../../variables.scss";

.button {
  height: 32px;
  border: 0;
  background: $button-background-default;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 0 $border-shadow-color;
  cursor: pointer;
  transition: box-shadow ease .2s;

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 0 20px;
  user-select: none;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 2px 2px 2px 0 $border-shadow-color;
  }
}

.primary {
  background: $button-background-primary;
  color: $button-invert-text-color;
}
